/* eslint-disable @typescript-eslint/no-unused-vars */
import Config from "@domain/entities/config-entity";
import { ConfigOptionsType, IGetConfig, IGetConfigOptions, IQueryConfig } from "@domain/interfaces/config-interface";
import ConfigRepository from "@domain/repositories/config-repository";
import { injectable } from "inversify";

@injectable()
export default class ConfigMockupRepository implements ConfigRepository {
  private item = {
    id: "123456",
    app_current_version: {
      ios: "1.0.9",
      android: "1.0.9",
    },
    test_list: [
      {
        phone: "0971965596",
        createdBy: "superadmin",
        time: "2023-10-17T09:24:30.997+0000",
      },
    ],
    createdBy: "admin",
    updatedBy: "admin",
    createdAt: "",
    updatedAt: "",
  };

  async getConfig (query: IQueryConfig): Promise<IGetConfig> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
  async updateConfig (body: Partial<Config>): Promise<IGetConfig> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async getOptions (type: ConfigOptionsType): Promise<IGetConfigOptions> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: [{ label: "", value: "" }],
    };
  }
}
