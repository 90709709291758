/* eslint-disable @typescript-eslint/no-unused-vars */
import FoodBooking from "@domain/entities/food-booking-entity";
import { IGetFoodBookingDetail, IGetFoodBookingList, IQueryFoodBooking, IUpdateFoodBooking } from "@domain/interfaces/food-booking-interface";
import FoodBookingRepository from "@domain/repositories/food-booking-repository";
import { injectable } from "inversify";

@injectable()
export default class FoodBookingMockupRepository implements FoodBookingRepository {
  private item = {
    id: "123456",
    employee_id: "",
    employee_name: "",
    booking_date: "",
    week: "",
    year: "",
    noon: "",
    createdBy: "admin",
    updatedBy: "admin",
    createdAt: "",
    updatedAt: "",
  };

  async getList (query: IQueryFoodBooking): Promise<IGetFoodBookingList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async create (body: FoodBooking): Promise<IGetFoodBookingDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async update (id: string, body: IUpdateFoodBooking): Promise<IGetFoodBookingDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async remove (id: string): Promise<IGetFoodBookingDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }
}
