import { DEFAULT_LIMIT } from "@common/constant";
import FoodBooking from "@domain/entities/food-booking-entity";
import { IGetFoodBookingDetail, IGetFoodBookingList, IQueryFoodBooking, IUpdateFoodBooking } from "@domain/interfaces/food-booking-interface";
import FoodBookingRepository from "@domain/repositories/food-booking-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class FoodBookingApiRepository implements FoodBookingRepository {
  private apiService = new ApiService();

  async getList (query: IQueryFoodBooking): Promise<IGetFoodBookingList> {
    const result = await this.apiService.get("/food", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: FoodBooking): Promise<IGetFoodBookingDetail> {
    const result = await this.apiService.post("/food", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: IUpdateFoodBooking): Promise<IGetFoodBookingDetail> {
    const result = await this.apiService.put(`/food/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetFoodBookingDetail> {
    const result = await this.apiService.remove(`/food/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
