/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import FoodBooking from "@domain/entities/food-booking-entity";
import { IGetFoodBookingDetail, IGetFoodBookingList, IQueryFoodBooking, IUpdateFoodBooking } from "@domain/interfaces/food-booking-interface";

export default abstract class FoodBookingRepository {
  async getList (query?: IQueryFoodBooking): Promise<IGetFoodBookingList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: FoodBooking): Promise<IGetFoodBookingDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: IUpdateFoodBooking): Promise<IGetFoodBookingDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetFoodBookingDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
