import ErrorCode, { API_APP_ERROR, API_SYS_ERROR } from "@common/kernel/error-code";
import { getFirebaseToken } from "@common/utils/firebase-message";
import { getSessionToken, removeSession } from "@infrastructure/session";
import axios, { AxiosError } from "axios";
import fileDownload from "js-file-download";

export interface IApiResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

export default class ApiService {
  host: string;
  headers: object;

  constructor () {
    this.host = gConfig.api.host;
    this.headers = {
      product: gConfig.api.product,
      token: gConfig.api.token,
      authorization: getSessionToken(),
      deviceInfo: {},
    };
  }

  private sessionTimeout = (code: number) => {
    if (code === ErrorCode.SESSION_TIMEOUT.code) {
      removeSession();
    }
  };

  async get (path: string, query: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers,
      });

      if (!response || !response.data) return API_SYS_ERROR as IApiResponse;

      const result: IApiResponse = response.data;
      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err: AxiosError = error;

      const result = <IApiResponse>err.response?.data;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async post (path: string, body: object, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;

      const response = await axios.post(url, body, {
        headers,
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;
      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async put (path: string, body: object, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.put(url, body, {
        headers,
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async remove (path: string, body?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.delete(url, { headers, data: body });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async save (path: string, method: "POST" | "GET" | "PUT", fileName: string, params?: any) {
    const url = `${this.host}${path}`;

    return await axios({
      url,
      method,
      responseType: "blob", // Important
      headers: this.headers,
      params,
    })
      .then((response) => {
        fileDownload(response.data, fileName);

        return { isSuccess: true };
      })
      .catch(() => {
        return { isSuccess: false };
      });
  }

  async upload (path: string, data: unknown) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.post(url, data, { headers: this.headers });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err = error as AxiosError;
        const result = err.response?.data as IApiResponse;
        this.sessionTimeout(result?.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  exportLink (path: string) {
    return `${this.host}${path}?authorization=${getSessionToken()}`;
  }
}
