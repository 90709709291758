/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetUserDetail, IGetUserList, IQueryUser } from "@domain/interfaces/user-interface";
import UserRepository from "@domain/repositories/user-repository";
import { injectable } from "inversify";

@injectable()
export default class UserMockupRepository implements UserRepository {
  private item = {
    id: "123456",
    name: "",
    employee_id: "",
    phone: "",
    organization_id: "",
    created_by: "admin",
    updated_by: "admin",
    created_at: "",
    updated_at: "",
    avatar: "",
    branch_id: "",
    status: "",
  };
  async getList (query: any): Promise<IGetUserList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async searchEmployee (query: IQueryUser): Promise<IGetUserList> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: { items: [], total: 0, limit: 10 },
    };
  }

  async update (id: string, body: any): Promise<IGetUserDetail> {
    return {
      isSuccess: true,
      message: "Thành công",
      data: this.item,
    };
  }

  async export (query: any): Promise<any> {
    return;
  }
}
