import dayjs from "dayjs";

export const DMY_HMS = "DD/MM/YYYY hh:mm:ss";

export const DMY_HM = "DD/MM/YYYY hh:mm";

export const YMD = "YYYY/MM/DD";

export const MDY = "MM/DD/YYYY";

export const HMS = "hh:mm:ss";

export const DMY = "DD-MM-YYYY";

export const YMD_HMS = "YYYY-MM-DD hh:mm:ss";

export const HM = "hh:mm";

export function getEndDay (input?: string | Date) {
  const date = input ? new Date(input) : new Date();
  date.setHours(23, 59, 59, 999);

  return date;
}

export function getStartDay (input?: string | Date) {
  const date = input ? new Date(input) : new Date();
  date.setHours(0, 0, 0, 0);

  return date;
}

export function dateToString (input?: Date | string, format?: string) {
  if (!input) return "";
  const fullTime = new Date(input);
  const month = fullTime.getMonth() + 1;
  const date = fullTime.getDate();
  const year = fullTime.getFullYear();
  const hours = fullTime.getHours();
  const minutes = fullTime.getMinutes();
  const seconds = fullTime.getSeconds();

  const time = {
    year,
    date: date < 10 ? `0${date}` : date,
    month: month < 10 ? `0${month}` : month,
    hour: hours < 10 ? `0${hours}` : hours,
    minute: minutes < 10 ? `0${minutes}` : minutes,
    seconds: seconds < 10 ? `0${seconds}` : seconds,
  };

  if (!format || format === DMY) return `${time.date}/${time.month}/${time.year}`;

  if (format === DMY_HMS) return `${time.date}/${time.month}/${time.year} ${time.hour}:${time.minute}:${time.seconds}`;

  if (format === DMY_HM) return `${time.date}/${time.month}/${time.year} ${time.hour}:${time.minute}`;

  if (format === YMD) return `${time.year}/${time.month}/${time.date}`;

  if (format === MDY) return `${time.month}/${time.date}/${time.year}`;

  if (format === HMS) return `${time.hour}:${time.minute}:${time.seconds}`;

  if (format === YMD_HMS) return `${time.year}/${time.month}/${time.date} ${time.hour}:${time.minute}:${time.seconds}`;

  if (format === MDY) return `${time.month}/${time.date}/${time.year}`;

  if (format === HM) return `${time.hour}:${time.minute}`;
}

export function diffSeconds (date1: Date, date2: Date) {
  return Math.abs(date2.getTime() - date1.getTime()) / 1000;
}

export function dayjsToISODate (date: any) {
  return dayjs(date).toDate().toISOString();
}
